<template>
  <div class="workspace-column w-100">
    <div class="panel panel-full w-100 h-100">
      <div class="panel-content">
        <div class="container-fluid pt-4">
          <div class="row mb-2">
            <div class="col-12 d-flex justify-content-between">
              <h1>
                {{ pageTitle }}
              </h1>

              <div
                class="col-12 col-md-6 mt-2 mt-md-0 d-flex justify-content-start justify-content-md-end"
              >
                <b-dropdown
                  v-if="selected.length > 0"
                  size="sm"
                  :text="selected.length + ' selected'"
                >
                  <!--<b-dropdown-item @click="activateAll()">Activate</b-dropdown-item>-->
                  <!--<b-dropdown-item @click="deactivateAll()">De-activate</b-dropdown-item>-->
                  <b-dropdown-item @click="confirmDeleteUsers()"
                    >Delete</b-dropdown-item
                  >
                </b-dropdown>

                <div class="w-50 d-inline-block mr-2">
                  <b-form-input
                    id="filter-input"
                    v-model="filter"
                    type="search"
                    placeholder="Search ..."
                    size="sm"
                  ></b-form-input>
                </div>

                <b-button variant="success" size="sm" v-b-modal.form-add-user>
                  <i class="fa fa-plus mr-1"></i>Add User
                </b-button>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-12">
              <!-- Main table element -->
              <b-table
                ref="selectableTable"
                :items="usersList"
                :fields="usersTableFields"
                :current-page="currentPage"
                :per-page="perPage"
                :filter="filter"
                :filter-included-fields="filterOn"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :sort-direction="sortDirection"
                stacked="md"
                show-empty
                small
                @filtered="onFiltered"
                selectable
                select-mode="multi"
                @row-selected="onRowSelected"
                striped
              >
                <template #head(selected)>
                  <b-form-checkbox @change="toggleAll"> </b-form-checkbox>
                </template>

                <template #cell(selected)="{ rowSelected }">
                  <template v-if="rowSelected">
                    <i
                      class="fa fa-check-square fa-lg text-success bg-white"
                    ></i>
                  </template>
                  <template v-else>
                    <i class="far fa-square fa-lg"></i>
                  </template>
                </template>

                <template #cell(cards)="row">
                  <div v-if="row.item.cards.length > 0">
                    <div class="d-flex align-items-end">
                      <b-button
                        variant="info"
                        size="sm"
                        @click="showCardDetails(row.item.cards[0])"
                      >
                        {{ row.item.cards[0].card_no }}
                      </b-button>

                      <div v-if="row.item.cards.length > 1">
                        <div
                          class="ml-2"
                          v-b-toggle="'cards_collapse-' + row.index"
                        >
                          {{ row.item.cards.length - 1 }}
                          more cards
                        </div>
                      </div>
                    </div>

                    <b-collapse
                      v-if="row.item.cards.length > 1"
                      invisible
                      :id="'cards_collapse-' + row.index"
                    >
                      <div
                        v-for="(card, index) in row.item.cards"
                        :key="card.card_no"
                        class="pt-1"
                      >
                        <b-button
                          v-if="index !== 0"
                          variant="info"
                          size="sm"
                          @click="showCardDetails(card)"
                        >
                          {{ card.card_no }}
                        </b-button>
                      </div>
                    </b-collapse>
                  </div>
                  <div v-else>-</div>
                </template>

                <template #cell(lift_cards)="row">
                  <div v-if="row.item.lift_cards.length > 0">
                    <div class="d-flex align-items-end">
                      <b-button
                        variant="info"
                        size="sm"
                        @click="showLiftCardDetails(row.item.lift_cards[0])"
                      >
                        {{ row.item.lift_cards[0].card_no }}
                      </b-button>

                      <div v-if="row.item.lift_cards.length > 1">
                        <div
                          class="ml-2"
                          v-b-toggle="'lift_cards_collapse-' + row.index"
                        >
                          {{ row.item.lift_cards.length - 1 }}
                          more cards
                        </div>
                      </div>
                    </div>

                    <b-collapse
                      v-if="row.item.lift_cards.length > 1"
                      invisible
                      :id="'lift_cards_collapse-' + row.index"
                    >
                      <div
                        v-for="(card, index) in row.item.lift_cards"
                        :key="card.card_no"
                        class="pt-1"
                      >
                        <b-button
                          v-if="index !== 0"
                          variant="info"
                          size="sm"
                          @click="showLiftCardDetails(card)"
                        >
                          {{ card.card_no }}
                        </b-button>
                      </div>
                    </b-collapse>
                  </div>
                  <div v-else>-</div>
                </template>

                <template #cell(car_plate_no)="row">
                  <div v-if="row.item.car_plate_no != null">
                    {{ row.item.car_plate_no }}
                  </div>
                  <div v-else>-</div>
                </template>

                <template #cell(actions)="row">
                  <b-button
                    variant="secondary"
                    size="sm"
                    v-b-modal.form-update-user
                    v-b-tooltip.hover
                    title="Update User"
                    @click="showUpdateUserForm(row.item, row.index)"
                    class="mr-1"
                  >
                    <i class="fa fa-edit"></i>
                  </b-button>
                  <b-button
                    variant="info"
                    size="sm"
                    @click="handleSelectedAcsPerson(row.item)"
                    class="mr-1"
                    v-b-tooltip.hover
                    title="Manage Cards"
                  >
                    <i class="fa fa-address-card"></i>
                  </b-button>
                  <b-button
                    variant="danger"
                    size="sm"
                    @click="confirmDelete(row.item, row.index)"
                    v-b-tooltip.hover
                    title="Delete User"
                  >
                    <i class="fa fa-trash-alt"></i>
                  </b-button>
                </template>
              </b-table>
            </div>
          </div>

          <!-- Modal -->
          <b-modal
            id="form-add-user"
            title="Add ACS User"
            ref="formAddUser"
            centered
            hide-footer
          >
            <b-form @submit="onSubmitAddForm">
              <b-form-group
                id="input-group-personname"
                label="Person Name:"
                label-for="input-personname"
              >
                <b-form-input
                  id="input-personname"
                  v-model="formAdd.person_name"
                  type="text"
                  placeholder="Enter Person Name"
                  required
                ></b-form-input>
              </b-form-group>

              <b-form-group
                id="input-group-personno"
                label="Person No:"
                label-for="input-personno"
              >
                <b-form-input
                  id="input-personno"
                  v-model="formAdd.person_no"
                  type="text"
                  placeholder="Enter Person No."
                  required
                ></b-form-input>
              </b-form-group>

              <b-form-group
                id="input-group-person_identity_no"
                label="Identity No:"
                label-for="input-person_identity_no"
              >
                <b-form-input
                  id="input-person_identity_no"
                  v-model="formAdd.person_identity_no"
                  type="text"
                  placeholder="Enter Identity No."
                  required
                ></b-form-input>
              </b-form-group>

              <b-form-group
                id="input-group-birth_date"
                label="Birth Date:"
                label-for="input-birth_date"
              >
                <b-form-datepicker
                  id="input-birth_date"
                  v-model="formAdd.birth_date"
                  button-variant="primary"
                  close-button-variant="primary"
                  nav-button-variant="primary"
                  selected-variant="primary"
                  required
                ></b-form-datepicker>
              </b-form-group>

              <b-form-group
                id="input-group-gender"
                label="Gender:"
                label-for="input-gender"
              >
                <b-form-radio v-model="formAdd.gender" name="Male" value="M"
                  >Male</b-form-radio
                >
                <b-form-radio v-model="formAdd.gender" name="Female" value="F"
                  >Female</b-form-radio
                >
              </b-form-group>

              <b-form-group
                id="input-group-car_plate_no"
                label="Car Plate No:"
                label-for="input-car_plate_no"
              >
                <b-form-input
                  id="input-car_plate_no"
                  v-model="formAdd.car_plate_no"
                  type="text"
                  placeholder="Enter Car Plate No."
                  required
                ></b-form-input>
              </b-form-group>

              <b-form-group
                id="input-group-persontype"
                label="Person Type:"
                label-for="input-persontype"
              >
                <b-form-input
                  id="input-persontype"
                  v-model="formAdd.person_type"
                  type="text"
                  placeholder="Enter Person Type"
                  required
                  disabled
                ></b-form-input>
              </b-form-group>

              <b-form-group
                id="input-group-join_date"
                label="Join Date:"
                label-for="input-join_date"
              >
                <b-form-datepicker
                  id="input-join_date"
                  v-model="formAdd.join_date"
                  button-variant="primary"
                  close-button-variant="primary"
                  nav-button-variant="primary"
                  selected-variant="primary"
                  required
                ></b-form-datepicker>
              </b-form-group>

              <div class="text-danger" v-if="formAddErrorMessage">
                {{ formAddErrorMessage }}
              </div>
              <div class="text-right">
                <b-button type="submit" variant="primary">Submit</b-button>
              </div>
            </b-form>
          </b-modal>

          <b-modal
            title="Update ACS User"
            id="form-update-user"
            ref="formUpdateUser"
            centered
            hide-footer
          >
            <b-form @submit="onSubmitUpdateForm">
              <b-form-group
                id="input-group-personname"
                label="Person Name:"
                label-for="input-personname"
              >
                <b-form-input
                  id="input-personname"
                  v-model="formUpdate.person_name"
                  type="text"
                  placeholder="Enter Person Name"
                  required
                ></b-form-input>
              </b-form-group>

              <b-form-group
                id="input-group-personno"
                label="Person No:"
                label-for="input-personno"
              >
                <b-form-input
                  id="input-personno"
                  v-model="formUpdate.person_no"
                  type="text"
                  placeholder="Enter Person No."
                  required
                  disabled
                ></b-form-input>
              </b-form-group>

              <b-form-group
                id="input-group-person_identity_no"
                label="Identity No:"
                label-for="input-person_identity_no"
              >
                <b-form-input
                  id="input-person_identity_no"
                  v-model="formUpdate.person_identity_no"
                  type="text"
                  placeholder="Enter Identity No."
                  required
                  disabled
                ></b-form-input>
              </b-form-group>

              <b-form-group
                id="input-group-birth_date"
                label="Birth Date:"
                label-for="input-birth_date"
              >
                <b-form-datepicker
                  id="input-birth_date"
                  v-model="formUpdate.birth_date"
                  button-variant="primary"
                  close-button-variant="primary"
                  nav-button-variant="primary"
                  selected-variant="primary"
                  required
                ></b-form-datepicker>
              </b-form-group>

              <b-form-group
                id="input-group-gender"
                label="Gender:"
                label-for="input-gender"
              >
                <b-form-radio v-model="formUpdate.gender" name="Male" value="M"
                  >Male</b-form-radio
                >
                <b-form-radio
                  v-model="formUpdate.gender"
                  name="Female"
                  value="F"
                  >Female</b-form-radio
                >
              </b-form-group>

              <b-form-group
                id="input-group-car_plate_no"
                label="Car Plate No:"
                label-for="input-car_plate_no"
              >
                <b-form-input
                  id="input-car_plate_no"
                  v-model="formUpdate.car_plate_no"
                  type="text"
                  placeholder="Enter Car Plate No."
                  required
                ></b-form-input>
              </b-form-group>

              <b-form-group
                id="input-group-persontype"
                label="Person Type:"
                label-for="input-persontype"
              >
                <b-form-input
                  id="input-persontype"
                  v-model="formUpdate.person_type"
                  type="text"
                  placeholder="Enter Person Type"
                  required
                  disabled
                ></b-form-input>
              </b-form-group>

              <b-form-group
                id="input-group-join_date"
                label="Join Date:"
                label-for="input-join_date"
              >
                <b-form-datepicker
                  id="input-join_date"
                  v-model="formUpdate.join_date"
                  button-variant="primary"
                  close-button-variant="primary"
                  nav-button-variant="primary"
                  selected-variant="primary"
                  required
                ></b-form-datepicker>
              </b-form-group>

              <div class="text-danger" v-if="formUpdateErrorMessage">
                {{ formUpdateErrorMessage }}
              </div>
              <div class="text-right">
                <b-button type="submit" variant="primary">Submit</b-button>
              </div>
            </b-form>
          </b-modal>

          <b-modal
            ref="selected-card-details"
            :hide-footer="true"
            centered
            title="Card"
          >
            <div class="d-flex justify-content-center">
              <div
                class="d-flex flex-column align-items-start bg-info rounded"
                style="width: 314px; height: 200px"
              >
                <div class="d-flex mb-auto pt-5 pl-1">
                  <h5>
                    <strong>
                      {{ selectedCardDetails.card_no }}
                    </strong>
                  </h5>
                </div>
                <div class="d-flex w-100 justify-content-end">
                  <div class="d-flex">
                    <div class="p-2">
                      <i class="fa fa-info-circle fa-lg"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="pt-5">
              <div>Card No: {{ selectedCardDetails.card_no }}</div>
              <div>Start Date: {{ selectedCardDetails.start_date }}</div>
              <div>Start Time: {{ selectedCardDetails.start_time }}</div>
              <div>End Date: {{ selectedCardDetails.end_date }}</div>
              <div>End Time: {{ selectedCardDetails.end_time }}</div>
              <div>
                Access Group:
                <span
                  v-for="access_group in selectedCardDetails.access_groups"
                  :key="access_group"
                >
                  <b-badge>{{ access_group }}</b-badge>
                </span>
              </div>
              <div>Access Level: {{ selectedCardDetails.access_level }}</div>
              <div>Activated: {{ selectedCardDetails.is_activated }}</div>
              <div>
                Anti Passback: {{ selectedCardDetails.is_anti_passback }}
              </div>
              <div>Pin No: {{ selectedCardDetails.pin_no }}</div>
              <div>Buddy No: {{ selectedCardDetails.buddy_no }}</div>
            </div>
          </b-modal>

          <b-modal
            ref="selected-lift-card-details"
            :hide-footer="true"
            centered
            title="Lift Card"
          >
            <div class="d-flex justify-content-center">
              <div
                class="d-flex flex-column align-items-start bg-info rounded"
                style="width: 314px; height: 200px"
              >
                <div class="d-flex mb-auto pt-5 pl-1">
                  <h5>
                    <strong>
                      {{ selectedLiftCardDetails.card_no }}
                    </strong>
                  </h5>
                </div>
                <div class="d-flex w-100 justify-content-end">
                  <div class="d-flex">
                    <div class="p-2">
                      <i class="fa fa-info-circle fa-lg"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="pt-5">
              <div>Card No: {{ selectedLiftCardDetails.card_no }}</div>
              <div>Start Date: {{ selectedLiftCardDetails.start_date }}</div>
              <div>Start Time: {{ selectedLiftCardDetails.start_time }}</div>
              <div>End Date: {{ selectedLiftCardDetails.end_date }}</div>
              <div>End Time: {{ selectedLiftCardDetails.end_time }}</div>

              <div>
                Lift Access Level:
                {{ selectedLiftCardDetails.lift_access_level }}
              </div>
              <div>Activated: {{ selectedLiftCardDetails.is_activated }}</div>
              <div>Pin No: {{ selectedLiftCardDetails.pin_no }}</div>
            </div>
          </b-modal>

          <b-modal
            ref="all-card-details"
            :hide-footer="true"
            centered
            :title="selectedAcsPerson.person_name + '\'s Cards'"
            :scrollable="true"
            size="xl"
          >
            <div class="pb-4">
              <b-button variant="primary" v-b-modal.addCardForm
                >Add Card</b-button
              >
            </div>

            <b-tabs content-class="mt-3">
              <b-tab title="Cards" active>
                <b-table :fields="cardFields" :items="selectedAcsPerson.cards">
                  <template #cell(actions)="row">
                    <!--                    <b-button-->
                    <!--                        variant="secondary"-->
                    <!--                        size="sm"-->
                    <!--                        v-b-tooltip.hover-->
                    <!--                        title="Update Card"-->
                    <!--                    >-->
                    <!--                      <i class="fa fa-edit"></i>-->
                    <!--                    </b-button>-->

                    <b-button
                      variant="danger"
                      size="sm"
                      v-b-tooltip.hover
                      title="Delete Card"
                      @click="confirmDeleteCard(row.item, 'card')"
                    >
                      <i class="fa fa-trash-alt"></i>
                    </b-button>
                  </template>
                </b-table>
              </b-tab>
              <b-tab title="Lift Card">
                <b-table
                  :fields="liftCardFields"
                  :items="selectedAcsPerson.lift_cards"
                >
                  <template #cell(actions)="row">
                    <!--                    <b-button-->
                    <!--                        variant="secondary"-->
                    <!--                        size="sm"-->
                    <!--                        v-b-tooltip.hover title="Update Card"-->
                    <!--                    >-->
                    <!--                      <i class="fa fa-edit"></i>-->
                    <!--                    </b-button>-->
                    <b-button
                      variant="danger"
                      size="sm"
                      title="Delete Card"
                      @click="confirmDeleteCard(row.item, 'lift-card')"
                    >
                      <i class="fa fa-trash-alt"></i>
                    </b-button>
                  </template>
                </b-table>
              </b-tab>
            </b-tabs>
          </b-modal>

          <b-modal
            id="addCardForm"
            title="Add Card"
            ref="addCardForm"
            centered
            hide-footer
            size="lg"
          >
            <b-form @submit="onSubmitAddCardForm">
              <b-form-group label="Card No">
                <b-input-group>
                  <b-form-input
                    v-model="generated_card_no"
                    disabled
                  ></b-form-input>
                  <b-input-group-append>
                    <b-button
                      variant="info"
                      @click="handleClickGenerateCardNo"
                      :disabled="generated_card_no != null"
                    >
                      Generate
                    </b-button>
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>

              <b-form-group label="Valid duration:">
                <date-range-picker
                  ref="picker"
                  v-model="addCardForm.dateRange"
                  :locale-data="{ firstDay: 1, format: 'dd-mm-yyyy HH:mm:ss' }"
                  :timePicker="true"
                >
                  <template v-slot:input="picker" style="min-width: 350px">
                    {{ picker.startDate | date }} - {{ picker.endDate | date }}
                  </template>
                </date-range-picker>
              </b-form-group>

              <b-form-group label="Access group:">
                <b-form-tags
                  v-model="addCardForm.access_groups"
                  required
                ></b-form-tags>
              </b-form-group>

              <b-form-group label="Access level:">
                <b-form-input
                  type="number"
                  v-model="addCardForm.access_level"
                  required
                ></b-form-input>
              </b-form-group>

              <b-form-group label="Card Access level:">
                <b-form-input
                  type="number"
                  v-model="addCardForm.card_access_level"
                  required
                ></b-form-input>
              </b-form-group>

              <b-form-group label="Pin No:">
                <b-form-input
                  :type="'number'"
                  v-model="addCardForm.pin_no"
                  required
                ></b-form-input>
              </b-form-group>

              <b-form-group label="Buddy No:">
                <b-form-input
                  :type="'number'"
                  v-model="addCardForm.buddy_no"
                  required
                ></b-form-input>
              </b-form-group>

              <b-form-group label="Card Type:">
                <b-form-radio
                  v-model="addCardForm.type"
                  name="card"
                  value="card"
                  >Card</b-form-radio
                >
                <b-form-radio
                  v-model="addCardForm.type"
                  name="lift-card"
                  value="lift-card"
                  >Lift Card</b-form-radio
                >
              </b-form-group>

              <b-form-group label="Activate:">
                <b-form-checkbox
                  v-model="addCardForm.is_activated"
                  switch
                ></b-form-checkbox>
              </b-form-group>

              <b-form-group label="Anti Passback:">
                <b-form-checkbox
                  v-model="addCardForm.is_anti_passback"
                  switch
                ></b-form-checkbox>
              </b-form-group>

              <div class="text-danger" v-if="addCardFormErrorMessage">
                {{ addCardFormErrorMessage }}
              </div>
              <div class="text-right">
                <b-button
                  type="submit"
                  variant="primary"
                  :disabled="generated_card_no == null"
                  >Submit</b-button
                >
              </div>
            </b-form>
          </b-modal>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import {
  required,
  minLength,
  maxLength,
  between,
  helpers
} from "vuelidate/lib/validators";
import AjaxFetch from "@/assets/global/js/AjaxFetch.js";
import DateRangePicker from "vue2-daterange-picker";
//you need to import the CSS manually
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import moment from "moment";

let utils = require("@/assets/global/js/utils.js");

const username_regex = helpers.regex(
  "username_regex",
  /^(?=[a-zA-Z0-9._]{4,20}$)(?!.*[_.]{2})[^_.].*[^_.]$/
);

export default {
  components: { DateRangePicker },
  data: function () {
    return {
      pageTitle: "Manage ACS Users",
      usersList: [],
      usersTableFields: [
        { key: "selected", label: "", thStyle: { width: "40px" } },
        {
          key: "person_no",
          label: "Person No",
          sortable: true,
          sortDirection: "desc"
        },
        {
          key: "person_name",
          label: "Person Name",
          sortable: true,
          sortDirection: "desc"
        },
        {
          key: "person_identity_no",
          label: "Identity No",
          sortable: true,
          sortDirection: "desc"
        },
        {
          key: "person_type",
          label: "Person Type",
          sortable: true,
          sortDirection: "desc"
        },
        { key: "cards", label: "Cards", sortable: true, sortDirection: "desc" },
        {
          key: "lift_cards",
          label: "Lift Cards",
          sortable: true,
          sortDirection: "desc"
        },
        {
          key: "car_plate_no",
          label: "Car Plate No.",
          sortable: true,
          sortDirection: "desc"
        },
        { key: "actions", label: "Actions" }
      ],
      selected: [],
      formAdd: {
        person_name: null,
        person_no: null,
        person_type: "Visitor",
        person_identity_no: null,
        car_plate_no: null,
        gender: "M",
        join_date: null,
        birth_date: null
      },
      formUpdate: {
        person_name: null,
        person_no: null,
        person_type: "Visitor",
        person_identity_no: null,
        car_plate_no: null,
        gender: "M",
        join_date: null,
        birth_date: null,
        cards: [],
        lift_cards: []
      },
      formAddErrorMessage: null,
      formUpdateErrorMessage: null,

      selectedCardDetails: {
        person_no: null,
        card_no: null,
        start_date: null,
        end_date: null,
        start_time: null,
        end_time: null,
        access_groups: null,
        access_level: null,
        is_activated: null,
        is_anti_passback: null,
        pin_no: null,
        buddy_no: null
      },
      selectedLiftCardDetails: {
        person_no: null,
        card_no: null,
        start_date: null,
        end_date: null,
        start_time: null,
        end_time: null,
        lift_access_level: null,
        is_activated: null,
        pin_no: null
      },

      selectedAcsPerson: {
        person_name: null,
        person_no: null,
        person_type: "Visitor",
        person_identity_no: null,
        car_plate_no: null,
        gender: "M",
        join_date: null,
        birth_date: null,
        cards: [],
        lift_cards: []
      },

      cardFields: [
        // {key: 'person_no', label: 'Person No', sortable: true, sortDirection: 'desc'},
        {
          key: "card_no",
          label: "Card No",
          sortable: true,
          sortDirection: "desc"
        },
        {
          key: "start_date",
          label: "Start Date",
          sortable: true,
          sortDirection: "desc"
        },
        {
          key: "start_time",
          label: "Start Time",
          sortable: true,
          sortDirection: "desc"
        },
        {
          key: "end_date",
          label: "End Date",
          sortable: true,
          sortDirection: "desc"
        },
        {
          key: "end_time",
          label: "End Time",
          sortable: true,
          sortDirection: "desc"
        },
        {
          key: "access_groups",
          label: "Access Groups",
          sortable: true,
          sortDirection: "desc"
        },
        {
          key: "access_level",
          label: "Access Level",
          sortable: true,
          sortDirection: "desc"
        },
        {
          key: "is_activated",
          label: "Is Activated",
          sortable: true,
          sortDirection: "desc"
        },
        {
          key: "is_anti_passback",
          label: "Is Anti Passback",
          sortable: true,
          sortDirection: "desc"
        },
        {
          key: "pin_no",
          label: "Pin No",
          sortable: true,
          sortDirection: "desc"
        },
        {
          key: "buddy_no",
          label: "Buddy No",
          sortable: true,
          sortDirection: "desc"
        },
        { key: "actions", label: "Actions" }
      ],
      liftCardFields: [
        // {key: 'person_no', label: 'Person No', sortable: true, sortDirection: 'desc'},
        {
          key: "card_no",
          label: "Card No",
          sortable: true,
          sortDirection: "desc"
        },
        {
          key: "start_date",
          label: "Start Date",
          sortable: true,
          sortDirection: "desc"
        },
        {
          key: "end_date",
          label: "End Date",
          sortable: true,
          sortDirection: "desc"
        },
        {
          key: "lift_access_level",
          label: "Lift Access Level",
          sortable: true,
          sortDirection: "desc"
        },
        {
          key: "is_activated",
          label: "Is Activated",
          sortable: true,
          sortDirection: "desc"
        },
        {
          key: "pin_no",
          label: "Pin No",
          sortable: true,
          sortDirection: "desc"
        },
        { key: "actions", label: "Actions" }
      ],

      currentUserID: null,
      totalRows: 1,
      currentPage: 1,
      perPage: 10000,
      pageOptions: [5, 10, 15, { value: 100, text: "Show a lot" }],
      sortBy: "id",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],

      generated_card_no: null,
      addCardForm: {
        type: "card",
        access_groups: ["AG1"],
        dateRange: {},
        access_level: 1,
        card_access_level: 1,
        pin_no: null,
        buddy_no: 0,
        is_activated: true,
        is_anti_passback: false
      },
      addCardFormErrorMessage: null
    };
  },
  computed: {
    ...mapGetters({
      getCurrentUser: "session/getCurrentUser",
      getAPIServerURL: "session/getAPIServerURL"
    })
  },
  filters: {
    dateCell(value) {
      let dt = new Date(value);

      return dt.getDate();
    },
    date(val) {
      return val ? val.toLocaleString() : "";
    }
  },

  watch: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => {
          return { text: f.label, value: f.key };
        });
    }
  },
  mounted: function () {
    this.getUsersList();
  },
  methods: {
    getUsersList: function () {
      var $this = this;
      var API_URL = $this.getAPIServerURL + "/api/acsservice/person/udf/";
      const client = $this.$root.getAjaxFetchClient();
      client
        .getRequest(API_URL)
        .then((data) => {
          var result = data.result;

          var validUrl1 = "";
          var validUrl2 = "";

          if (result != undefined) {
            outer_loop: for (var key in result) {
              for (var key2 in result[key]) {
                if (
                  result[key][key2] != undefined &&
                  Array.isArray(result[key][key2])
                ) {
                  validUrl1 = key;
                  validUrl2 = key2;
                  break outer_loop;
                }
              }
            }
          }

          if (
            result[validUrl1][validUrl2] != undefined &&
            Array.isArray(result[validUrl1][validUrl2])
          ) {
            for (var key3 in result[validUrl1][validUrl2]) {
              $this.$store.dispatch(
                "acsUsers/addUser",
                result[validUrl1][validUrl2][key3]
              );
            }
            this.fetchUsers();
          }
        })
        .catch((err) => {
          $this.$store.dispatch("session/addGlobalAlertMessage", {
            message_text: err.detail,
            message_type: "danger"
          });
          // if (err.status == 401) {
          //   $this.$store.dispatch("session/logoutSession");
          // }
        });
    },
    fetchUsers: function () {
      this.usersList = this.$store.getters["acsUsers/getUsers"](null);
    },
    onRowSelected(items) {
      this.selected = items;
    },
    selectAllRows() {
      this.$refs.selectableTable.selectAllRows();
    },
    clearSelected() {
      this.$refs.selectableTable.clearSelected();
    },

    toggleAll(checked) {
      if (checked) {
        this.selectAllRows();
      } else {
        this.clearSelected();
      }
    },

    showUpdateUserForm: function (item, index) {
      this.formUpdate.person_name = item.person_name;
      this.formUpdate.person_no = item.person_no;
      this.formUpdate.person_identity_no = item.person_identity_no;
      this.formUpdate.car_plate_no = item.car_plate_no;
      this.formUpdate.gender = item.gender;
      this.formUpdate.join_date = item.join_date;
      this.formUpdate.birth_date = item.birth_date;
      this.formUpdate.cards = item.cards;
      this.formUpdate.lift_cards = item.lift_cards;

      this.formUpdateErrorMessage = null;
    },

    onSubmitAddForm: function (e) {
      e.preventDefault();
      var $this = this;

      var API_URL = $this.getAPIServerURL + "/api/acsservice/person/";
      const client = $this.$root.getAjaxFetchClient();

      client
        .postRequest(API_URL, $this.formAdd)
        .then((data) => {
          if (data.detail != undefined) {
            this.formAddErrorMessage = data.detail;
          } else {
            let newPerson = {
              ...$this.formAdd,
              cards: [],
              lift_cards: []
            };

            // add new user to store
            $this.$store.dispatch("acsUsers/addUser", newPerson);

            // call fetch users
            $this.fetchUsers();

            $this.$store.dispatch("session/addGlobalAlertMessage", {
              message_text:
                "User " +
                $this.formAdd.person_name +
                " has been added successfully",
              message_type: "success"
            });

            $this.formAdd.person_name = null;
            $this.formAdd.person_no = null;
            $this.formAdd.person_type = "Visitor";
            $this.formAdd.person_identity_no = null;
            $this.formAdd.car_plate_no = null;
            $this.formAdd.gender = "M";
            $this.formAdd.join_date = null;
            $this.formAdd.birth_date = null;

            $this.formAddErrorMessage = null;
            $this.$refs.formAddUser.hide();
          }
        })
        .catch((err) => {
          $this.$store.dispatch("session/addGlobalAlertMessage", {
            message_text: err.detail,
            message_type: "danger"
          });
          // if (err.status == 401) {
          //   $this.$store.dispatch("session/logoutSession");
          // }
        });
    },
    onSubmitUpdateForm: function (e) {
      e.preventDefault();
      var $this = this;
      var API_URL = $this.getAPIServerURL + "/api/acsservice/person/udf/";
      const client = $this.$root.getAjaxFetchClient();
      client
        .putRequest(API_URL, $this.formUpdate)
        .then((data) => {
          if (data.detail != undefined) {
            this.formUpdateErrorMessage = data.detail;
          } else {
            $this.$store.dispatch("acsUsers/updateUser", {
              person_no: $this.formUpdate.person_no,
              data: $this.formUpdate
            });

            $this.formUpdate.person_name = null;
            $this.formUpdate.person_no = null;
            $this.formUpdate.person_type = "Visitor";
            $this.formUpdate.person_identity_no = null;
            $this.formUpdate.car_plate_no = null;
            $this.formUpdate.gender = "M";
            $this.formUpdate.join_date = null;
            $this.formUpdate.birth_date = null;
            $this.formUpdate.cards = [];
            $this.formUpdate.lift_cards = [];

            $this.formUpdateErrorMessage = null;
            $this.$refs.formUpdateUser.hide();
            $this.$store.dispatch("session/addGlobalAlertMessage", {
              message_text: "Updated user successfully",
              message_type: "success"
            });
          }
        })
        .catch((err) => {
          $this.$store.dispatch("session/addGlobalAlertMessage", {
            message_text: err.detail,
            message_type: "danger"
          });
          // if (err.status == 401) {
          //   $this.$store.dispatch("session/logoutSession");
          // }
        });
    },

    confirmDeleteUsers: function (e) {
      // e.preventDefault();
      var $this = this;
      if ($this.selected.length > 0) {
        $this.$bvModal
          .msgBoxConfirm(
            "Confirm deleting " + $this.selected.length + " users?",
            {
              centered: true
            }
          )
          .then((value) => {
            // console.log(value);
            if (value == true) {
              for (var f in $this.selected) {
                var user_data = $this.selected[f];
                $this.deleteUser(user_data);
              }

              this.clearSelected();
            }
          })
          .catch((err) => {
            // An error occurred
          });
      } else {
        $this.$bvModal.msgBoxOk("No users selected", { centered: true });
      }
    },

    confirmDelete: function (user_data, index) {
      // e.preventDefault();
      var $this = this;
      this.$bvModal
        .msgBoxConfirm("Confirm deleting " + user_data.person_name + "?", {
          centered: true
        })
        .then((value) => {
          if (value == true) {
            $this.deleteUser(user_data);
          }
        })
        .catch((err) => {
          // An error occurred
        });
    },
    deleteUser: function (user_data) {
      var $this = this;
      var API_URL = $this.getAPIServerURL + "/api/acsservice/person/";
      const client = $this.$root.getAjaxFetchClient();
      client
        .deleteRequest(API_URL, user_data)
        .then((data) => {
          $this.$store.dispatch("acsUsers/deleteUser", user_data);
          this.fetchUsers();

          this.$store.dispatch("session/addGlobalAlertMessage", {
            message_text: "Deleted user successfully",
            message_type: "success"
          });
        })
        .catch((err) => {
          $this.$store.dispatch("session/addGlobalAlertMessage", {
            message_text: err.detail,
            message_type: "danger"
          });
          // if (err.status == 401) {
          //   $this.$store.dispatch("session/logoutSession");
          // }
        });
    },

    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },

    showCardDetails(payload) {
      this.selectedCardDetails = payload;
      this.$refs["selected-card-details"].show();
    },
    showLiftCardDetails(payload) {
      this.selectedLiftCardDetails = payload;
      this.$refs["selected-lift-card-details"].show();
    },

    handleSelectedAcsPerson(payload) {
      this.selectedAcsPerson = payload;
      this.$refs["all-card-details"].show();
    },

    onSubmitAddCardForm: function (e) {
      e.preventDefault();
      let $this = this;

      let API_URL = "";

      if ($this.addCardForm.type === "lift-card") {
        API_URL = $this.getAPIServerURL + "/api/acsservice/person/lift-card/";
      } else {
        API_URL = $this.getAPIServerURL + "/api/acsservice/person/card/";
      }

      let start_date = moment(this.addCardForm.dateRange.startDate).format(
        "YYYY-MM-DD"
      );
      let start_time = moment(this.addCardForm.dateRange.startDate).format(
        "HH:MM:SS"
      );
      let end_date = moment(this.addCardForm.dateRange.endDate).format(
        "YYYY-MM-DD"
      );
      let end_time = moment(this.addCardForm.dateRange.endDate).format(
        "HH:MM:SS"
      );

      let newData = {
        person_no: this.selectedAcsPerson.person_no,
        card_no: this.generated_card_no,
        start_date: start_date,
        start_time: start_time,
        end_date: end_date,
        end_time: end_time,
        ...this.addCardForm
      };

      const client = $this.$root.getAjaxFetchClient();

      client
        .postRequest(API_URL, newData)
        .then((data) => {
          if (data.detail != undefined) {
            this.addCardFormErrorMessage = data.detail;
          } else {
            if ($this.addCardForm.type === "lift-card") {
              $this.selectedAcsPerson.lift_cards.push(newData);
            } else {
              $this.selectedAcsPerson.cards.push(newData);
            }

            $this.$store.dispatch("acsUsers/updateUser", {
              person_no: $this.selectedAcsPerson.person_no,
              data: $this.selectedAcsPerson
            });

            $this.fetchUsers();

            $this.$store.dispatch("session/addGlobalAlertMessage", {
              message_text:
                "Success add " +
                $this.addCardForm.type +
                " " +
                $this.generated_card_no +
                " to " +
                $this.selectedAcsPerson.person_name +
                " successfully",
              message_type: "success"
            });

            /// reset

            $this.generated_card_no = null;
            $this.addCardForm.type = "card";
            $this.addCardForm.access_groups = ["AG1"];
            $this.addCardForm.dateRange = {};
            $this.addCardForm.access_level = 1;
            $this.addCardForm.card_access_level = 1;
            $this.addCardForm.pin_no = null;
            $this.addCardForm.buddy_no = 0;
            $this.addCardForm.is_activated = true;
            $this.addCardForm.is_anti_passback = false;
            $this.addCardFormErrorMessage = null;

            $this.$refs.addCardForm.hide();
          }
        })
        .catch((err) => {
          $this.$store.dispatch("session/addGlobalAlertMessage", {
            message_text: err.detail,
            message_type: "danger"
          });
          // if (err.status == 401) {
          //   $this.$store.dispatch("session/logoutSession");
          // }
        });
    },

    handleClickGenerateCardNo() {
      this.$bvModal
        .msgBoxConfirm("Do you want to generate a new card no?", {
          title: "Generate New Card No",
          size: "sm",
          buttonSize: "sm",
          okVariant: "primary",
          okTitle: "YES",
          cancelTitle: "NO",
          footerClass: "p-2",
          hideHeaderClose: false,
          centered: true
        })
        .then((value) => {
          if (value) {
            this.requestNewCardNo();
          }
        })
        .catch((err) => {
          // An error occurred
        });
    },
    requestNewCardNo: function () {
      var $this = this;
      var API_URL = $this.getAPIServerURL + "/api/acsservice/card/";
      const client = $this.$root.getAjaxFetchClient();
      client
        .getRequest(API_URL)
        .then((data) => {
          var result = data.result;

          var validUrl1 = "";
          var validUrl2 = "";

          if (result != undefined) {
            outer_loop: for (var key in result) {
              for (var key2 in result[key]) {
                if (result[key][key2] != undefined) {
                  validUrl1 = key;
                  validUrl2 = key2;
                  break outer_loop;
                }
              }
            }
          }

          if (result[validUrl1][validUrl2] != undefined) {
            this.generated_card_no = result[validUrl1][validUrl2];
          }
        })
        .catch((err) => {
          $this.$store.dispatch("session/addGlobalAlertMessage", {
            message_text: err.detail,
            message_type: "danger"
          });
          // if (err.status == 401) {
          //   $this.$store.dispatch("session/logoutSession");
          // }
        });
    },

    confirmDeleteCard: function (payload, type) {
      // e.preventDefault();
      var $this = this;
      this.$bvModal
        .msgBoxConfirm("Confirm deleting " + payload.card_no + "?", {
          centered: true
        })
        .then((value) => {
          if (value) {
            $this.deleteCard(payload, type);
          }
        })
        .catch((err) => {
          // An error occurred
        });
    },
    deleteCard: function (payload, type) {
      let $this = this;
      let API_URL;

      if (type == "lift-card") {
        API_URL = $this.getAPIServerURL + "/api/acsservice/person/lift-card/";
      } else {
        API_URL = $this.getAPIServerURL + "/api/acsservice/person/card/";
      }

      const client = $this.$root.getAjaxFetchClient();
      client
        .deleteRequest(API_URL, payload)
        .then((data) => {
          $this.$store.dispatch("acsUsers/deleteUserCard", {
            type: type,
            ...payload
          });
          this.fetchUsers();

          this.$store.dispatch("session/addGlobalAlertMessage", {
            message_text: "Deleted card successfully",
            message_type: "success"
          });
        })
        .catch((err) => {
          $this.$store.dispatch("session/addGlobalAlertMessage", {
            message_text: err.detail,
            message_type: "danger"
          });
          // if (err.status == 401) {
          //   $this.$store.dispatch("session/logoutSession");
          // }
        });
    }
  }
};
</script>

<style lang="scss">
@import "./App.scss";
</style>
